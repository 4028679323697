/* Menu.css */

.menu-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    text-align: center;
  }
  
  .menu-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }
  
  .menu-item img {
    width: 100%;
    height: auto;
  }
  
  .item-details {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
  
  .item-details h3 {
    margin-top: 0;
  }
  
  .item-details p {
    margin-bottom: 0;
  }
  