/* Contact.css */

.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-info {
    margin-bottom: 20px;
  }
  
  .contact-form {
    margin-top: 20px;
  }
  
  .contact-form h3 {
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #ff6347;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #e55d41;
  }
  