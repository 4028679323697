/* Header.css */

header {
    background-color: rgb(164, 93, 68);
    padding: 20px;
    box-shadow: 0 2px 4px rgba(149, 83, 83, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    font-size: 20px;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  nav ul li a:hover {
    color: #ff6347; /* Change color on hover to coral */
  }
  