/* Footer.css */

footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-container p {
    margin: 0;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
    margin-left: 10px;
    font-size: 20px;
  }
  
  .social-links a:hover {
    color: #ff6347;
  }
  